<template>
  <b-row>
    <b-col md="7">
      <b-card>
        <b-row>
          <b-col>
            <b-row>
              <b-col md="12">
                <h2>
                  Lead - {{ dados.nomecontato }}
                </h2>
                <br>
              </b-col>
              <b-col
                md="6"
              >
                <b-form-group>
                  <v-select
                    id="catalogaStatus"
                    v-model="statusLeadCategoriaConfirma"
                    label="nome"
                    :options="statusLeadCategoria"
                    placeholder="Esse Lead Converteu?"
                  />
                </b-form-group>
              </b-col>
              <b-col
                md="2"
                style="margin-top: 5px;"
              >
                <b-button
                  size="sm"
                  variant="outline-primary"
                  @click="confirmaStatus()"
                >
                  Confirmar
                </b-button>
              </b-col>
              <b-row>
                <b-col>
                  <dl
                    class="row"
                  >
                    <dt
                      v-for="(comp, index) in dados.complemento"
                      :key="index"
                      class="col-md-12"
                    >
                      <b-row>
                        <b-col md="12">
                          <b-list-group-item>
                            <b-row>
                              <b-col md="5">
                                <h5>{{ comp.nome }} :</h5>
                              </b-col>
                              <b-col md="7">
                                {{ comp.valor }}
                              </b-col>
                            </b-row>
                          </b-list-group-item>
                        </b-col>
                      </b-row>
                    </dt>
                  </dl>
                </b-col>
              </b-row>
            </b-row>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
    <b-col md="5">
      <b-card>
        <b-row>
          <h2>Atividades :</h2>
        </b-row>
        <br>
        <dl
          v-for="(atv, index) in atividades"
          :key="index"
          class="col-md-12"
        >
          <b-row>
            <b-col md="12">
              <b-list-group-item>
                <b-row>
                  <b-col md="12">
                    <b-row>
                      <h5>Status :</h5>
                    </b-row>
                    {{ atv.status.nome }}
                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <h5>Relatorio de Atendimento : </h5>
                    </b-row>
                    {{ atv.relatorio }}
                    <!--                    <b-form-textarea-->
                    <!--                      id="textarea-default"-->
                    <!--                      v-model="atv.relatorio"-->
                    <!--                      rows="3"-->
                    <!--                      disabled-->
                    <!--                    />-->
                  </b-col>
                </b-row>
              </b-list-group-item>
            </b-col>
          </b-row>
        </dl>
      </b-card>
    </b-col>
    <b-modal
      id="popup-confirmacao"
      title="Confirme o Novo Status do Lead"
      ok-only
      hide-footer
    >
      <b-card-text>
        <span>Escreva o relatório do atendimento com esse Lead</span>
      </b-card-text>
      <b-form-textarea
        id="textarea-rows"
        v-model="relatorioLead"
        placeholder="Relatório do atendimento"
        rows="8"
        class="mb-2"
      />
      <hr>
      <b-button
        variant="primary"
        block
        @click="salvarConversao()"
      >
        Salvar
      </b-button>
    </b-modal>
  </b-row>
</template>

<script>
import { BListGroupItem, BFormTextarea } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Dados',
  components: {
    vSelect,
    BListGroupItem,
    BFormTextarea,
  },
  data() {
    return {
      id: null,
      dados: {},
      atividades: {},
      nomecontato: '',
      relatorioLead: '',
      statusLeadCategoriaConfirma: '',
      statusLeadCategoria: [],
      data: {},
      select: [],
      status: '',
      listaStatus: [],
      interesse: '',
      interesseLista: [],
      statusLead: '',
      selected: '',
      dadositem: {},
    }
  },
  created() {
    this.BuscarDados()
    this.statusLeads()
  },
  methods: {
    reloadTable() {
      this.BuscarDados()
    },
    statusLeads() {
      this.$http.get('admin/status').then(resp => {
        this.statusLeadCategoria = resp.data.data
      })
    },
    BuscarDados() {
      this.dados = JSON.parse(localStorage.getItem('leadnovo'))
      this.BuscarAtividades()
    },
    BuscarAtividades() {
      this.$http.get(`admin/atividades/${this.dados.id}`).then(resp => {
        this.atividades = resp.data.data
      })
    },
    Notificacao(cor, msg) {
      this.$bvToast.toast(msg, {
        title: 'ALERTA',
        variant: cor,
        solid: true,
      })
    },
    confirmaStatus() {
      if (this.statusLeadCategoriaConfirma === '' || this.statusLeadCategoriaConfirma === null) {
        this.Notificacao('danger', 'Selecione o Status do Lead')
      } else {
        this.$bvModal.show('popup-confirmacao')
      }
    },
    async salvarConversao() {
      if (this.relatorioLead.length < 10) {
        this.Notificacao('danger', `Você preencheu ${this.relatorioLead.length} caracteres onde o minimo é 10`)
        return
      }
      const obj = {
        id: this.dados.id,
        status_id: this.statusLeadCategoriaConfirma.id,
        observacao: this.relatorioLead,
      }
      await this.$http.post(`admin/kanban/attkanban/${this.dados.id}`, obj).then(resp => {
        if (resp.data.response === true) {
          this.Notificacao('success', resp.data.mensagem)
        }
        if (resp.data.response === false) {
          this.Notificacao('danger', resp.data.mensagem)
        }
        this.relatorioLead = ''
        this.$bvModal.hide('popup-confirmacao')
        this.mostraPopUp = false
        this.reloadTable()
      })
    },
  },
}
</script>

<style scoped>

</style>
